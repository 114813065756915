import React from "react";
import {graphql} from "gatsby";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import SEO from "../components/SEO";
import {Link} from "gatsby";

const AboutStyles = styled.div`
  width: auto;
  max-width: 2000px;
  padding-right: 3vw;
  padding-left: 3vw;
  padding-top: 6.6vmax;
  padding-bottom: 6.6vmax;
  display: flex;
  justify-content: center;
  .about-content {
    width: 65%;
    display: flex;
  }
  .about-image {
    flex:1 0 35%;
    margin-right: 5%;
  }
  .about-text {
    flex:1 0 60%;
    font-weight: 300;
    line-height: 1.65;
    font-size: calc((1.2 - 1) * 1.2vw + 1rem);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    p {
      margin: 0 0 1rem 0;
    }
  }
  .about-contact-btn {
    letter-spacing: 1px;
    cursor: pointer;
    color: #000000;
    background: transparent;
    transition: background-color .2s ease;
    border: 1px solid #000000;
    font-size: .9rem;
    display: inline;
    width: auto;
    border-radius: 0;
    padding: 1rem 1.5rem;
    text-decoration: none;
  }

  .about-contact-btn:hover {
    background-color: #000000;
    color: #ffffff;
  }

  @media screen and (max-width: 800px){
    width: auto;
    padding-right: 6vw;
    padding-left: 6vw;
    .about-content {
      width: 100%;
      flex-direction: column;
    }

    .about-image {
      margin-right: 0;
      margin-bottom: 5%;
    }
  }
`;

function AboutPage({data}){
  console.log(data.about.aboutText);
    return (
        <>
            <SEO title={"About"}/>
            <AboutStyles>
              <div className="about-content">
                <div className="about-image">
                    <GatsbyImage image={data.about.image.asset.gatsbyImageData} alt={"Alexander Peterson Image"} />
                </div>
                <div className="about-text">
                  <div className="about-text--paragraphs">
                    {data.about.aboutText.map(item => <p>{item.children[0]["text"]}</p>)}
                  </div>
                  <div className="about-text--contact">
                    <Link to="/contact" className="about-contact-btn">Contact</Link>
                  </div>
                </div>
              </div>
            </AboutStyles>
        </>
    )
}

export default AboutPage;

export const about = graphql`
    query AboutQuery {
        about: sanityAbout {
          aboutText {
            children {
              text
            }
          }
            image {
                asset {
                    gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
                }
            }
        }
    }
`;
